














import { Component, Vue } from 'vue-property-decorator'
import { getPlatform } from '@/utils/verify'

@Component({
  name: 'Spread'
})
export default class Spread extends Vue {
  // 是否显示引导图
  isModel = false

  // 下载
  handelDownload() {
    const pla = getPlatform()
    if (pla === 'weixin' || pla === 'qq') {
      this.isModel = true
    } else {
      console.log('进入浏览器器页面的时候打印一下')
      // 如果用户已经安装App直接跳转App
      window.location.href = 'allo://tel.top/home/callshow?select=1'
      // 如果用户打开App失败，直接跳转下载
      setTimeout(function() {
        window.location.href = process.env.VUE_APP_dianshi
      }, 500)
      this.isModel = false
    }
  }
}
